import React, {useContext} from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { Container } from "../grid"

import { Box } from "@mui/system"
import { Typography } from "@mui/material"

import { appContext } from "../contexts"
import { openSearch } from "../contexts/actions"

export default function NotFoundPage(params) {

  const {setApp} = useContext(appContext)

  const handleClick = (e) => {
    e.preventDefault()
    setApp(openSearch())
  }

  return (
    <Container>
      <Box sx = { (theme) => ({
        textAlign: 'center'
      })}>
      <Box
          sx = { (theme) => ({
            width: '80%',
            maxWidth: '25rem',
            margin: '0 auto',
            mb: 8
          })}
        >
          <StaticImage 
            src="../images/404.png" alt="Stran ne obstaja" 
            placeholder="none"
          />
          {/* <Typography variant="caption"><a href="https://absurd.design/">@absurd.design</a></Typography> */}
        </Box>
        <Typography variant='h1'>Stran ne obstaja (404)</Typography>
        <Typography variant="h3">Kar ste si želeli ogledati, ni na voljo.</Typography>
        <Typography variant="h4">Preverite črkovanje, <a href="/" className="underlined-link underlined-link--kontra" onClick={handleClick}>uporabite iskalnik</a> ali se vrnite na <Link className="underlined-link underlined-link--kontra" to="/">začetno stran.</Link></Typography>
        
      </Box>
    </Container>
  )
};

